import '@/sass/fonts.scss'

function application () {
  const Vue = import('vue')
  const App = import('./App.vue')
  const router = import('./router')
  const store = import('./store')
  const vuetify = import('./plugins/vuetify')
  const i18n = import('./plugins/vue-i18n')
  const storage = import('vue-ls')
  const api = import('@/lib/api')

  Promise.all([Vue, App, router, store, vuetify, i18n, storage, api]).then(results => {
    const [{ default: Vue }, { default: App }, { default: router }, { default: store }, { default: vuetify }, { default: i18n }, { default: storage }, { default: api }] = results

    Vue.config.productionTip = false

    Vue.use(api)
    Vue.use(storage)

    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App),
    }).$mount('#app')
  })
}

application()
